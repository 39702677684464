import styled from "styled-components";

const Link = styled.a`
    text-transform: uppercase;
    color: #fff;
    font-size: 22px;
`

export const S = {
    Link
}
